






























import { Vue, Component, Prop } from 'vue-property-decorator';
import MConfirmModal from '@/shared/components/MConfirmModal.vue';
import MButton from '@/shared/components/MButton.vue';

@Component({
  components: {
    MConfirmModal,
    MButton,
  },
})
export default class RemoveIntegrationModal extends Vue {
  translatePrefix = 'menu.sparkpay.sales.invoice_report.invoice_sidebar';

  @Prop({ type: String }) modalId: string;
  @Prop({ type: String }) title: string;

  confirmed() {
    this.$emit('confirm');
    this.$bvModal.hide(this.modalId);
  }

  cancelled() {
    this.$emit('cancel');
    this.$bvModal.hide(this.modalId);
  }
}
